module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'Digital Garden', // Navigation and Site Title
  siteTitleAlt: 'Sebastian\'s Digital Garden', // Alternative Site title for SEO
  siteTitleShort: 'Digital Garden', // short_name for manifest
  siteUrl: 'https://notes.sebastianauner.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'The Digital Garden of Sebastian Auner.',
  author: 'Sebastian Auner', // Author for schemaORGJSONLD
  organization: 'Sebastian Auner',

  siteFBAppID: '', // Facebook App ID - Optional
  userTwitter: '', // Twitter Username
  ogSiteName: 'Digital Garden', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#004B23',
  backgroundColor: '#2b2e3c',

  // Social component
  twitter: '',
  twitterHandle: '',
  github: 'https://github.com/Ctrl-A-Del/',
  linkedin: '',
}
