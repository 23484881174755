import React from 'react'
import { Link } from 'gatsby'

export default () => {
  return (
    <React.Fragment>
      <Link to="/blog" activeClassName="active" aria-label="View all notes">
        All notes
      </Link>
      {/* <Link to="#" activeClassName="active" aria-label="View blog page">
        About
      </Link> */}
      <a href="https://sebastianauner.com">
        Contact
      </a>

    </React.Fragment>
  )
}
