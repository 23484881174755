import React from 'react'
import { css } from '@emotion/core'
import { bpMaxSM } from '../lib/breakpoints'
import Container from './Container'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGitlab, faGithub } from '@fortawesome/free-brands-svg-icons'
import { useTheme } from 'components/Theming'

const Footer = ({ author }) => {
  const theme = useTheme()
  return (
    <footer>
      <Container
        css={css`
          padding-top: 0;
          ${bpMaxSM} {
            padding-top: 0;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              font-size: 90%;
              opacity: 0.7;
            `}
          >
            {author && `${author} \u00A9 ${new Date().getFullYear()}`}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-evenly;
              font-size: 12px;
              flex-grow: 1;
              ${bpMaxSM} {
                order: -1;
                justify-content: space-between;
              }
            `}
          >
            <Link to="/legal-notice">Legal Notice</Link>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy">Privacy</Link>
            <Link to="/attributions">Attributions</Link>
          </div>
          {/* <Twitter /> */}
          <div css={css`
            display: flex;
            flex-grow: 1;
            justify-content: space-around;
          `}>
            <a
              href="https://github.com/Ctrl-A-Del"
              css={css`
                color: ${theme.colors.text};
                font-size: 21px;
              `}
            >
              <FontAwesomeIcon icon={ faGithub } />
            </a>
            <a
              href="https://gitlab.com/Ctrl-A-Del"
              css={css`
                color: ${theme.colors.text};
                font-size: 21px;
              `}
            >
              <FontAwesomeIcon icon={ faGitlab } />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
